import React from 'react'
import { useDevice } from 'device'
import { cx } from 'helpers'

import { Image } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'

import logosImage from './images/logos.jpg'


const BrandsRowAb: React.FunctionComponent = () => {
  const { isMobile } = useDevice()

  return (
    <WidthContainer
      className={cx('flex bg-light-beige', isMobile && 'no-scrollbar touch-manipulation overflow-x-auto overflow-y-hidden')}
      ignore={isMobile}
      size={1440}
    >
      <Image
        className="mx-auto h-[108rem] max-w-[976rem]"
        src={logosImage}
        sizes={isMobile ? '976px' : '1066px'}
        alt="Versace, Dolce & Gabbana, Gucci and other brands"
      />
    </WidthContainer>
  )
}


export default BrandsRowAb
